import React from "react";
import "../../style/main.scss";
import { Link } from "react-router-dom";
import Button from "./button";
import StrokeButton from "./strokeButton";
import { useLocation } from "react-router-dom";
import BarIcon from "../../assets/image/Bar_Icon.svg";
import logowhite from "../../assets/icons/Updated_Logo.png";
const Navbar = () => {
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark p-0">
        <div class="container-fluid text-white nav_bar">
          <a class="navbar-brand text-white logo" href="/">
            <img src={logowhite} alt="" />
          </a>

          <button
            class="navbar-toggler shadow-none  "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon text-white "></span>
          </button>
          <div
            class="collapse navbar-collapse center_nav"
            id="navbarSupportedContent"
          >
            <div className="links collapse navbar-collapse">
              <ul className="list navbar-nav me-auto mb-2 mb-lg-0 "></ul>
            </div>

            <form class="d-flex buttons ms-5" role="search">
              <StrokeButton text="Create Account" route={"/signup"} />
              <Button text="login" route={"/login"} />
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
