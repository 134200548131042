// import React from "react";
import NewTopBar from "../component/newTopBar";
// import Topbar from "../../User/components/topBar";
import "./dashboard.scss";
import { Icon } from "@iconify/react";
import axios from "axios";
import { GRAPHICS } from "../../User/assets";
import Google_icon from "../assets/icon/google_icon.png";
import { React, useState, useEffect } from "react";
import diff from "fast-diff";
import SqlEditor from "../component/SqlEditor";
import { toast } from "react-toastify";
import { getUserProfile } from "../../../firebase/firebase";
import { Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
// import { Icon } from "@iconify/react";
import { CsvToHtmlTable } from "react-csv-to-table";
import { ClipLoader } from "react-spinners";
import NewSqlEditor from "../component/NewSqlEditor";
import { updateEmail } from "firebase/auth";
import { use } from "react";

function Dashboard({
  isSideMenuOpen,
  toggleSideMenu,
  bookmark,
  activeTab,
  setActiveTab,
  isAdmin,
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [bookmarkName, setBookmarkName] = useState("");
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    if (bookmark) {
      let id = bookmark?.QuestionName;
      setBookmarkName(id);
    }
  }, []);

  const [show, setShow] = useState(false);
  const [desktopshow, setDesktopShow] = useState(false);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [user, setUser] = useState(null);
  const [unlockshow, setUnlockShow] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [hintData, setHintData] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [error, setError] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [outputTableData, setOutputTableData] = useState(null);
  const [points, setPoints] = useState(0);
  const [query, setQuery] = useState("");
  const [aiHints, setAiHints] = useState([]);
  const [aiHintsLoading, setAIHintsLoading] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [noQuestion, setNoQuestion] = useState(false);
  const [attempts, setAttempts] = useState(0);

  const [aiResponse, setAiResponse] = useState("");
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const [aiEvaluationLoading, setAIEvaluationLoading] = useState(false);
  const [showStatus, setShowStatus] = useState(null);
  const [filterdata, setFilterdata] = useState(null);
  const [isRunQueryClicked, setRunQueryClicked] = useState(false);
  const [refreshPoints, setRefreshPoints] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);

  const handleClose = () => setUnlockShow(false);
  const handleShow = () => {
    setUnlockShow(true);
    handleAIHints();
  };

  const solutionHandleClose = () => setShowSolution(false);
  const solutionHandleShow = () => {
    setShowSolution(true);
  };

  const handleFilterChange = (filtersData) => {
    setFilterdata(filtersData);
  };

  // Apis
  const isProduction = process.env.NODE_ENV === "production";
  const apiUrlGetQuestions = isProduction
    ? process.env.REACT_APP_API_URL_GET_QUESTIONS_PROD
    : process.env.REACT_APP_API_URL_GET_QUESTIONS;
  const apiUrlGetTables = isProduction
    ? process.env.REACT_APP_API_URL_GET_TABLENAME_PROD
    : process.env.REACT_APP_API_URL_GET_TABLENAME;
  const apiUrlExecute = isProduction
    ? process.env.REACT_APP_API_URL_EXECUTESQL_PROD
    : process.env.REACT_APP_API_URL_EXECUTESQL;

  const apiUrlGetHints = isProduction
    ? process.env.REACT_APP_API_URL_GET_HINT_PROD
    : process.env.REACT_APP_API_URL_GET_HINT;

  const apiUrlAiHints = isProduction
    ? process.env.REACT_APP_API_URL_GENERATE_AI_HINTS_PROD
    : process.env.REACT_APP_API_URL_GENERATE_AI_HINTS;

  const apiUrlAiEvaluation = isProduction
    ? process.env.REACT_APP_API_URL_GENERATE_AI_EVALUATION_PROD
    : process.env.REACT_APP_API_URL_GENERATE_AI_EVALUATION;

  const apiUrlSolvedQuestion = isProduction
    ? process.env.REACT_APP_API_URL_SOLVED_QUESTIONS_PROD
    : process.env.REACT_APP_API_URL_SOLVED_QUESTIONS;

  const apiUrlGetSolvedQuestion = isProduction
    ? process.env.REACT_APP_API_URL_GET_SOLVED_QUESTIONS_PROD
    : process.env.REACT_APP_API_URL_GET_SOLVED_QUESTIONS;

  const apiUrlAddBookmark = isProduction
    ? process.env.REACT_APP_API_URL_ADD_BOOKMARK_PROD
    : process.env.REACT_APP_API_URL_ADD_BOOKMARK;

  const apiUrlGetBookmark = isProduction
    ? process.env.REACT_APP_API_URL_GET_BOOKMARK_PROD
    : process.env.REACT_APP_API_URL_GET_BOOKMARK;

  const apiUrlTrackAttempts = isProduction
    ? process.env.REACT_APP_API_URL_ADD_ATTEMPT_PROD
    : process.env.REACT_APP_API_URL_ADD_ATTEMPT;

  const apiUrlGetAttempts = isProduction
    ? process.env.REACT_APP_API_URL_GET_ATTEMPTS_PROD
    : process.env.REACT_APP_API_URL_GET_ATTEMPTS;

  // Fetch all questions data
  const fetchQuestions = async () => {
    try {
      // alert("calling questions");
      const questionsResponse = await axios.get(apiUrlGetQuestions);
      setQuestionData(questionsResponse.data);
      const totalQuestions = questionsResponse.data.length;
      setTotalQuestion(totalQuestions);
      if (totalQuestions === 0) {
        setQuestionLoading(true);
      }

      // Fetch solved question data
      if (user) {
        const solvedResponse = await axios.get(
          `${apiUrlGetSolvedQuestion}?userId=${user.uid}`
        );
        setGetSolvedQuestion(solvedResponse.data);
        setSolvedQuestionLength(solvedResponse.data.length);

        // Update the questionData array with solvedStatus field
        const updatedQuestionData = questionsResponse.data.map((question) => {
          const hasMatchingUserId = solvedResponse.data.some(
            (a) => a.questionId === question.QuestionID
          );
          return {
            ...question,
            solvedStatus: hasMatchingUserId, // true if there is a match, false otherwise
          };
        });
        setQuestionData(updatedQuestionData);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [user]);

  let currentQuestion = questionData[currentQuestionIndex];

  const fetchBookmarks = async () => {
    try {
      if (user) {
        const bookmarksResponse = await axios.get(
          `${apiUrlGetBookmark}?userId=${user.uid}`
        );
        const updatedQuestionData = questionData.map((question) => {
          const hasMatchingQuestionId = bookmarksResponse.data.some(
            (bookmark) => bookmark.bookmarkedQuestionId === question?.QuestionID
          );
          return {
            ...question,
            bookmarkStatus: hasMatchingQuestionId, // true if there is a match, false otherwise
          };
        });

        setQuestionData(updatedQuestionData);
      }
    } catch (error) {
      console.error("Error fetching bookmarks:", error.message);
    }
  };
  useEffect(() => {
    fetchBookmarks();
  }, [user, currentQuestionIndex]);

  // colors for easy hard medium difficulty level

  function getButtonColor(difficultyLevel) {
    switch (difficultyLevel?.toLowerCase()) {
      case "easy":
        return "#78be06";
      case "medium":
        return "#007cb9";
      case "hard":
        return "#dc2f2f";
      default:
        return "transparent";
    }
  }

  // Get table schema

  useEffect(() => {
    const getTableSchema = async () => {
      try {
        const schemaPromises = currentQuestion?.TableNames.map((tableName) =>
          axios.get(`${apiUrlGetTables}?tableName=${tableName}`)
        );

        const schemaResponses = await Promise.all(schemaPromises);
        const tableSchemas = schemaResponses.map((response) => response.data);
        setTableLoading(false);

        const textAfterHyphenArray = currentQuestion.TableNames.map(
          (tableName) => {
            const tableNameParts = tableName.split("_");
            const extractedName = tableNameParts[tableNameParts.length - 1];
            return extractedName;
          }
        );

        setTableData([
          { tableName: textAfterHyphenArray, schema: tableSchemas },
        ]);
      } catch (error) {
        console.error("Error fetching table schema:", error);
      }
    };
    getTableSchema();
  }, [currentQuestionIndex, questionData]);

  // Get hints
  const getHints = async () => {
    if (currentQuestion) {
      try {
        const response = await axios.get(
          `${apiUrlGetHints}?questionID=${currentQuestion.QuestionID}`
        );
        const hints = response.data;
        setHintData(hints);
      } catch (error) {
        console.error("Error fetching hints", error);
      }
    }
  };

  useEffect(() => {
    getHints();
  }, [currentQuestionIndex, tableData]);

  const handleQueryChange = (query) => {
    setQuery(query);
  };

  const handleRunClick = async () => {
    // alert("Executing");
    let updateQuery = query;
    tableData[0].tableName.forEach((tableNameElement, index) => {
      const regex = new RegExp(`\\b${tableNameElement}\\b`, "gi");

      // Use a function as the replacement to preserve the original case
      updateQuery = updateQuery.replace(regex, (match) => {
        // Preserve the case of the matched text
        return match === tableNameElement
          ? currentQuestion?.TableNames[index]
          : match;
      });
    });

    try {
      const response = await axios.post(apiUrlExecute, {
        sqlQuery: updateQuery,
      });

      if (response.data.error) {
        setOutputTableData(null);
        setError(response.data.error);
        setApiResponse(null);
      } else {
        setApiResponse(response.data.result);
        setError(null);
        setRunQueryClicked(true);
      }

      let userId = user?.uid;
      if (!outputTableData && !currentQuestion?.solvedStatus) {
        try {
          await axios.post(apiUrlTrackAttempts, {
            userId: userId,
            questionId: currentQuestion?.QuestionID,
            attempt: 1,
          });
        } catch (error) {
          console.error("Error adding attempt:", error);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.response.data.error || "An unexpected error occurred.");
        setApiResponse(null);
        setOutputTableData(null);
      } else {
        console.error("Error:", error);
        setError("An unexpected error occurred.");
        setApiResponse(null);
        setOutputTableData(null);
      }
    }
  };

  //  convert json data to simplified
  const convertJsonToSimplified = (data) => {
    return (
      data
        ?.map((entry, index) => {
          const values = Object.values(entry);
          if (index === 0) {
            const keys = Object.keys(entry);
            return [keys.join(","), values.join(",")].join("\n");
          }
          return values.join(",");
        })
        .join("\n") || ""
    );
  };

  const handleAIHints = async () => {
    try {
      // Set loading state to true before making the API call
      setAIHintsLoading(true);

      const tableNamesArray = tableData.map((entry) => entry.tableName);

      const response = await axios.post(apiUrlAiHints, {
        query: query,
        question: currentQuestion?.QuestionName,
        tables: tableNamesArray,
        solution: outputTableData,
      });

      // Assuming the server response contains the AI evaluation result in a field called 'aiResult'
      setAiHints(response.data.sqlQuery);
    } catch (error) {
      console.error("Error making AI evaluation request:", error.message);
    } finally {
      setAIHintsLoading(false);
    }
  };

  const JsonToSimplified = apiResponse && convertJsonToSimplified(apiResponse);

  const convertToSimpleCSV = (data) => {
    const rows = data?.split("\\n");
    const cleanedRows = rows?.map(
      (row) =>
        row.replace(/\\/g, "").replace(/"/g, "").replace(/r$/, "").trim() || ""
    );
    const simplifiedCSV = cleanedRows?.join("\n") || "";
    return simplifiedCSV;
  };

  // compare output and expected output

  //  filter data based on filters in topbar

  useEffect(() => {
    let updatedFilteredQuestions = [...questionData];

    if (filterdata?.database) {
      updatedFilteredQuestions = updatedFilteredQuestions.filter(
        (data) => data.exercise_id === filterdata.database
      );
    }

    if (filterdata?.level) {
      updatedFilteredQuestions = updatedFilteredQuestions.filter(
        (data) => data.DifficultyLevel === filterdata.level
      );
    }

    if (filterdata?.sqlType) {
      updatedFilteredQuestions = updatedFilteredQuestions.filter(
        (data) => data.sqlType === filterdata.sqlType
      );
    }
    if (bookmark) {
      updatedFilteredQuestions = updatedFilteredQuestions.filter(
        (question) => question.QuestionName === bookmarkName
      );
    }

    if (updatedFilteredQuestions.length === 0) {
      setNoQuestion(true);
    } else {
      setNoQuestion(false);
    }

    setFilteredQuestions(updatedFilteredQuestions);
  }, [filterdata, questionData, bookmark]);

  const simplifiedCSV = convertToSimpleCSV(currentQuestion?.ExpectedOutput);

  function compareResults(simplifiedCSV, JsonToSimplified) {
    console.log(simplifiedCSV, JsonToSimplified, "Test");
    const trimmedCSV = simplifiedCSV?.trim() || "";
    const trimmedJson = JsonToSimplified?.trim() || "";
    const differences = diff(trimmedCSV, trimmedJson);
    if (differences.every(([action]) => action === 0)) {
      setOutputTableData(true);
      setShowStatus(true);
    } else {
      setOutputTableData(false);
      setShowStatus(true);
    }
  }
  useEffect(() => {
    if (apiResponse) {
      compareResults(simplifiedCSV, JsonToSimplified);
    }
  }, [apiResponse, simplifiedCSV, JsonToSimplified, handleAIHints]);

  const submitHandleClose = () => setSubmitFeedback(false);
  const submitHandleShow = () => {
    // Check if both query and outputTableData area truthy
    if (query && outputTableData) {
      setSubmitFeedback(true);
      handleAIEvaluation();
    } else {
      toast.info("Please solve question for evaluation.");
    }
  };

  const handleAIEvaluation = async () => {
    // Check if both query and outputTableData are truthy
    if (query && outputTableData) {
      try {
        // Set loading state to true before making the API call
        setAIEvaluationLoading(true);
        const response = await axios.post(apiUrlAiEvaluation, {
          userQuery: query,
        });

        setAiResponse(response.data.sqlQuery);
      } catch (error) {
        console.error("Error making AI evaluation request:", error.message);
        toast.error(
          "An error occurred while evaluating the query. Please try again."
        );
      } finally {
        setAIEvaluationLoading(false);
      }
    }
  };

  // get id of current user

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profile = await getUserProfile();
        setUser(profile);
      } catch (error) {
        // Handle errors if necessary
        console.error("Error fetching user profile:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setQuery("");
    setShowStatus(false);
    setPoints("");
    setRunQueryClicked(false);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (currentQuestion && currentQuestion.DifficultyLevel) {
      const { DifficultyLevel } = currentQuestion;
      let initialPoints;
      if (DifficultyLevel === "Easy") {
        initialPoints = 5;
      } else if (DifficultyLevel === "Medium") {
        initialPoints = 20;
      } else if (DifficultyLevel === "Hard") {
        initialPoints = 40;
      }

      if (
        outputTableData &&
        currentQuestion?.QuestionID &&
        user &&
        initialPoints
      ) {
        // Fetch attempts
        axios
          .get(
            `${apiUrlGetAttempts}?userId=${user?.uid}&questionId=${currentQuestion?.QuestionID}`
          )
          .then((res) => {
            const attemptsData = res.data.attempts;

            if (Array.isArray(attemptsData) && attemptsData.length > 0) {
              const attempts = attemptsData[0].attempt;

              // Calculate points based on attempts
              let calculatedPoints = initialPoints;

              if (attempts >= 3) {
                calculatedPoints -= 1;
              }

              if (attempts >= 6) {
                calculatedPoints -= 2;
              }

              if (attempts >= 9) {
                calculatedPoints -= 3;
              }
              setPoints(calculatedPoints);

              // Submit solved question with calculated points
              const solvedQuestion = async () => {
                try {
                  let response = await axios.post(apiUrlSolvedQuestion, {
                    userId: user.uid,
                    Name: user.displayName,
                    questionId: currentQuestion?.QuestionID,
                    points: calculatedPoints,
                  });

                  fetchQuestions();
                  // getSolveQuestion();
                  setRefreshPoints(!refreshPoints);
                } catch (error) {
                  // Handle errors here
                  console.error(
                    "Error submitting solved question:",
                    error.message
                  );
                }
              };

              if (!currentQuestion.solvedStatus) {
                solvedQuestion();
              }
            } else {
              setPoints(initialPoints);
              // If no attempts, use the initial points to submit the solved question
              const solvedQuestionWithoutAttempts = async () => {
                try {
                  let response = await axios.post(apiUrlSolvedQuestion, {
                    userId: user.uid,
                    Name: user.displayName,
                    questionId: currentQuestion?.QuestionID,
                    points: initialPoints,
                  });
                  setRefreshPoints(!refreshPoints);
                } catch (error) {
                  // Handle errors here
                  console.error(
                    "Error submitting solved question (no attempts):",
                    error.message
                  );
                }
              };
              if (!currentQuestion.solvedStatus) {
                solvedQuestionWithoutAttempts();
              }
            }
          })
          .catch((error) => {
            console.log(error, "Error in fetching attempts");
          });
      }
    }
  }, [outputTableData]);

  // GET SOLVED QUESTIONS
  const [getSolvedQuestion, setGetSolvedQuestion] = useState([]);
  const [solvedQuestionLength, setSolvedQuestionLength] = useState(0);
  const [percetageOfQuestion, setPrecentageOfQuestion] = useState(0);

  useEffect(() => {
    const percentage = ((solvedQuestionLength / totalQuestion) * 100).toFixed(
      2
    );
    setPrecentageOfQuestion(percentage);
  }, [getSolvedQuestion]);

  useEffect(() => {
    setOutputTableData(false);
  }, [currentQuestionIndex]);

  // Add bookmarks
  const addBookmark = async () => {
    let userId = user?.uid;
    try {
      const response = await axios.post(apiUrlAddBookmark, {
        questionId: currentQuestion.QuestionID,
        userId: userId,
      });
      toast.success(response.data.result);
      fetchBookmarks();
    } catch (error) {
      console.error("Error adding bookmark:", error);
    }
  };

  // Call the addBookmark function

  // useEffect(()=>{
  //   if(filteredQuestions){
  //     setQuestionLoading(true);
  //   }
  // },[filteredQuestions]);

  // console.log(tableLoading, "tableLoading");

  return (
    <div className="dashboard">
      {!bookmark ? (
        <NewTopBar
          refreshPoints={refreshPoints}
          onFilterChange={handleFilterChange}
          totalQuestion={totalQuestion}
          isSideMenuOpen={isSideMenuOpen}
          toggleSideMenu={toggleSideMenu}
          solvedQuestionLength={solvedQuestionLength}
          percentage={percetageOfQuestion}
          isAdmin={isAdmin}
        />
      ) : (
        <div className="bookmark-head">
          <span>Bookmarked Question</span>
        </div>
      )}

      <div className="content">
        <div className={`sidebar ${show ? "showsidebar" : "hidesidebar"}`}>
          <button
            className="sidebarbtn"
            onClick={() => {
              setShow(!show);
            }}
          >
            {" "}
            <Icon
              icon="icon-park-outline:double-left"
              color="white"
              width="25"
              height="25"
            />
            Collapse Slidebar
          </button>

          {filteredQuestions?.map((data, index) => (
            <div
              className="sidecard"
              key={index}
              onClick={() => {
                setCurrentQuestionIndex(index);
                setShow(false);
              }}
            >
              <div className="icon">
                <img src={Google_icon} alt="" />
              </div>
              <div className="card_content">
                <p>{data.QuestionName}</p>
                <button
                  style={{
                    backgroundColor: getButtonColor(data.DifficultyLevel),
                  }}
                >
                  {data.DifficultyLevel}
                </button>
                {data.solvedStatus}
              </div>
            </div>
          ))}
        </div>

        {desktopshow ? (
          <>
            <div className="desktoptoggle">
              <Icon
                icon="material-symbols:double-arrow"
                color="white"
                width="25"
                height="25"
                onClick={() => {
                  setDesktopShow(!desktopshow);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={`sidebar2 ${
                desktopshow ? "hidesidebar2" : "showsidebar2"
              }`}
            >
              <button
                className="sidebarbtn"
                onClick={() => {
                  setDesktopShow(!desktopshow);
                }}
              >
                {" "}
                <Icon
                  icon="icon-park-outline:double-left"
                  color="white"
                  width="20"
                  height="20"
                />
                Collapse Slidebar
              </button>
              {/* {noQuestion && (
                <p style={{ color: "white" }}>
             
                  No Questions
                </p>
              )} */}

              {filteredQuestions?.map((data, index) => (
                <div
                  className="sidecard"
                  key={index}
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  {/* <div className="icon">
                  <img src={Google_icon} alt="" />
                </div> */}
                  <div className="card_content">
                    <p>
                      {data.solvedStatus && (
                        <Icon
                          style={{
                            border: "",
                            background: "#78BE06",
                          }}
                          icon="typcn:tick"
                          color="white"
                          width="15"
                          height="14"
                        />
                      )}
                    </p>
                    <p>{data.QuestionName}</p>

                    <div style={{ display: "flex", columnGap: "120px" }}>
                      <button
                        style={{
                          backgroundColor: getButtonColor(data.DifficultyLevel),
                        }}
                      >
                        {data.DifficultyLevel}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <div
          className={`right_content ${
            desktopshow ? "fullwidth" : "right_content"
          }`}
        >
          <div className="sidebar_toggle">
            <Icon
              icon="material-symbols:double-arrow"
              color="white"
              width="25"
              height="25"
              onClick={() => {
                setShow(!show);
              }}
            />
          </div>
          <div className="dashboard_content">
            <div className="question_section">
              {currentQuestion?.bookmarkStatus || bookmark ? (
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className="bookmark-icon"
                >
                  <Icon
                    onClick={addBookmark}
                    icon="ion:bookmarks"
                    width="20"
                    height="20"
                    style={{ color: "yellow" }}
                  />
                </div>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className="bookmark-icon"
                >
                  <Icon
                    onClick={addBookmark}
                    icon="ion:bookmarks"
                    width="20"
                    height="20"
                  />
                </div>
              )}

              {
                <div className="box">
                  <button
                    style={{
                      backgroundColor: getButtonColor(
                        currentQuestion?.DifficultyLevel
                      ),
                    }}
                  >
                    {currentQuestion?.DifficultyLevel}
                  </button>
                  {!noQuestion && <h1> Question Description</h1>}

                  {noQuestion && (
                    <p
                      style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      No Questions Added Yet !
                    </p>
                  )}

                  <span>{currentQuestion?.QuestionName}</span>
                  {!noQuestion && (
                    <div className="accordion-btn">
                      <Accordion className="accordion">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="accordion_header">
                            <Icon
                              icon="lucide:key"
                              color="white"
                              width="20"
                              height="20"
                            />
                            View Hint
                          </Accordion.Header>
                          <Accordion.Body>
                            {tableLoading && !questionLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <ClipLoader color="white" size={25} />
                              </div>
                            ) : (
                              hintData.map((hint, index) => (
                                <p key={index}> - {hint.Hint}</p>
                              ))
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  )}
                </div>
              }
              {!noQuestion && (
                <div className="box">
                  <p>Source Tables:</p>

                  {tableLoading && !questionLoading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ClipLoader color="white" size={25} />
                    </div>
                  )}

                  {!noQuestion &&
                    tableData?.map(({ tableName, schema }) => (
                      <div key={tableName.join("-")}>
                        {schema?.map((fields, index) => (
                          <div key={index} className="blue_box">
                            <div className="box_header">
                              <h2>Table Name: {tableName[index]}</h2>
                            </div>
                            <div className="box_details">
                              <div className="fields-container">
                                {fields.map((field, fieldIndex) => (
                                  <div key={fieldIndex} className="field">
                                    <span>
                                      <strong>{field.Field}</strong>:
                                    </span>

                                    <span>{field.Type}</span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  {/* </div> */}
                </div>
              )}

              {!noQuestion && (
                <div className="box">
                  <p>Expected Output:</p>
                  <div className="half_width">
                    <div style={{ overflow: "scroll" }} className="box_details">
                      <CsvToHtmlTable
                        data={simplifiedCSV}
                        csvDelimiter=","
                        tableClassName="table  table-hover table-custom-width"
                        style={{ marginLeft: "50%" }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="feedback_section">
              <div className="box">
                <div className="buttons">
                  {!isAdmin && (
                    <button onClick={handleRunClick} className="run_query">
                      Run Query
                    </button>
                  )}
                  {(currentQuestion?.solvedStatus == true || isAdmin) && (
                    <button onClick={solutionHandleShow} className="run_query">
                      Show Answer
                    </button>
                  )}

                  <button onClick={submitHandleShow} className="run_query">
                    AI Evaluation
                  </button>
                </div>

                <div className="editor">
                  <NewSqlEditor
                    onQueryChange={handleQueryChange}
                    value={query}
                  />
                </div>

                <div className="buttons_bottom">
                  <div className="button_">
                    <button
                      onClick={() => setActiveTab("Report Bugs")}
                      className="unlock"
                    >
                      Report Bugs
                    </button>

                    <button onClick={handleShow} className="unlock">
                      AI Hints
                    </button>
                  </div>
                </div>
              </div>

              {error && <div style={{ color: "white" }}>{error} </div>}
              {showStatus && (
                <div className="outputStatus">
                  {query && outputTableData === true ? (
                    <>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <p>Your answer is correct</p>

                          <Icon
                            className="thumbsIcon"
                            icon="gridicons:thumbs-up"
                            width="40"
                            height="40"
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          {!currentQuestion?.solvedStatus && (
                            <div>
                              <p>{points} added!</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : isRunQueryClicked &&
                    query &&
                    outputTableData === false ? (
                    <>
                      <p>Your answer is wrong</p>
                      <Icon
                        className="thumbsIcon"
                        icon="mdi:thumb-down"
                        width="40"
                        height="40"
                      />
                    </>
                  ) : null}
                </div>
              )}

              <div className="result_header">
                <h1>Query Output</h1>
              </div>

              <div style={{ overflow: "scroll" }} className="result">
                {!error && query && isRunQueryClicked && outputTableData && (
                  <CsvToHtmlTable
                    data={JsonToSimplified}
                    csvDelimiter=","
                    tableClassName="table  table-hover table-custom-width"
                    style={{ marginLeft: "50%" }}
                  />
                )}
              </div>
            </div>

            {/* unlock modal */}

            <Modal
              centered
              show={unlockshow}
              onHide={handleClose}
              className="unlock_modal"
            >
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h3>AI Hints</h3>

                {aiHintsLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <ClipLoader color="white" size={25} />
                  </div>
                ) : (
                  aiHints
                )}
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            {/* submit feedback */}

            <Modal
              centered
              show={submitFeedback}
              onHide={submitHandleClose}
              className="submit_modal"
            >
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title> </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h3>AI Evaluation</h3>
                {aiEvaluationLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <ClipLoader color="white" size={25} />
                  </div>
                ) : (
                  <>
                    <div> {aiResponse}</div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
              size="lg"
              centered
              show={showSolution}
              onHide={solutionHandleClose}
              className="show_solution_modal"
            >
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title>
                  Question {currentQuestionIndex + 1}\ Solution
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <h4>Query:</h4>
                  <p>{currentQuestion?.solutionQuery}</p>
                </div>
                <div>
                  <h4>Explanation:</h4>
                  <p>{currentQuestion?.solutionExplanation}</p>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
