import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./page/home";
import Navbar from "./components/shared/navbar";
import Footer from "./components/shared/footer";
import Signup from "./components/account/signup";
import Login from "./components/account/login";
import Forget from "./components/account/forget";
import TermsConditions from "./page/terms&condition/termsConditions";
import UserDashboard from "./dashboard/User/userDashboard";
import Dashboard from "./dashboard/newUser/newuserdashboard/dashboard";
import Admindashboard from "./dashboard/admin/admin/admindashboard/admindashboard";
import SuccessPayment from "./dashboard/User/content/successPayment";
import FailPayment from "./dashboard/User/content/failPayment";

import { useEffect, useState } from "react";
// import TermsConditions from "./page/terms&condition/termsConditions";
import PrivacyPolicy from "./page/privacypolicy/privacyPolicy";
import RefundPolicy from "./page/Refund/refundPolicy";
import ContactUs from "./page/contactus/contactUs";
import { ClipLoader } from "react-spinners";
import { checkAuthState } from "./firebase/firebase";
import axios from "axios";
import { getUserDataForCurrentUser } from "../src/firebase/firebase";

function App() {
  let { pathname } = window.location;

  pathname = pathname.split("/")[1];

  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [loggedAsAdmin, setLoggedAsAdmin] = useState(false);

  const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;
  const password = process.env.REACT_APP_ADMIN_PASSWORD;
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getUserDataForCurrentUser();
        if (res) {
          // Use strict equality check
          if (res?.isAdmin === true) {
            // console.log("yes is admjn");
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []);

  useEffect(() => {
    const fetchAuthState = async () => {
      try {
        const loginSuccess = await checkAuthState();

        if (loginSuccess) {
          // console.log("logged in ");
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
          // console.log("logged out");
        }
      } catch (error) {
        console.error("Error checking authentication state:", error);
      }
    };

    fetchAuthState();
  }, [location]);

  if (isLoggedIn === null) {
    return (
      <div
        className="App"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ToastContainer />
        <div className="loading">
          <ClipLoader />
        </div>
      </div>
    );
  }

  const handleLogin = (loggedInAsAdmin) => {
    // Do something with the loggedInAsAdmin value

    setLoggedAsAdmin(loggedInAsAdmin);
  };

  return (
    <div className="App">
      <ToastContainer />
      {/* {pathname !== "userDashboard" && <Navbar />} */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/forget" element={<Forget />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/refund" element={<RefundPolicy />} />
        <Route path="/contactus" element={<ContactUs />} />

        <Route path="/old-user-dashboard" element={<UserDashboard />} />
        <Route path="/SuccessPayment" element={<SuccessPayment />} />
        <Route path="/FailPayment" element={<FailPayment />} />

        <Route
          path="/user-dashboard"
          element={
            isLoggedIn ? (
              <UserDashboard isAdmin={isAdmin} />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />

        <Route path="/admin-dashboard" element={<Admindashboard />} />
      </Routes>
    </div>
  );
}

export default App;
